import React, { FC, useCallback, useEffect, useState } from 'react';
import { HttpClient, WidgetProps } from '@wix/yoshi-flow-editor';
import {
  Button,
  ButtonPriority,
  Checkbox,
  Dropdown,
  Text,
  TextArea,
  TextField,
  TextTypography,
} from 'wix-ui-tpa';
import { st, classes } from './Widget.st.css';
import Upload from './upload';
import {
  createProduct,
  getProduct,
  updateProduct,
} from '@wix/ambassador-bazaar-v1-product/http';
import { Condition } from '@wix/ambassador-bazaar-v1-product/build/cjs/types';

export type ControllerProps = {
  instance: string;
  productId?: string;
  navigateToFeed: Function;
};

const currency = [
  { id: 'EUR', value: '€', isSelectable: true },
  { id: 'USD', value: '$', isSelectable: true },
  { id: 'ILS', value: '₪', isSelectable: true },
  { id: 'PLN', value: 'zł', isSelectable: true },
];

const conditions = [
  { id: Condition.USED, value: 'Used', isSelectable: true },
  { id: Condition.NEW, value: 'New', isSelectable: true },
];

const locations = [
  { id: 'vilnius', value: 'Vilnius', isSelectable: true },
  { id: 'us', value: 'United States', isSelectable: true },
  { id: 'telaviv', value: 'Tel Aviv', isSelectable: true },
  { id: 'krakow', value: 'Krakow', isSelectable: true },
];

const shippingOptions = [
  { id: 'post', value: 'Post', isSelectable: true },
  { id: 'in-person', value: 'In person', isSelectable: true },
  { id: 'either', value: 'Post or In person', isSelectable: true },
];

const statuses = [
  { id: 'available', value: 'Available', isSelectable: true },
  { id: 'reserved', value: 'Reserved', isSelectable: true },
];

const Widget: FC<WidgetProps<ControllerProps>> = ({
  instance,
  productId,
  navigateToFeed,
}) => {
  const [values, setValues] = useState({
    price: { currency: currency[0].id },
    images: [],
    currency: currency[0].id,
    condition: conditions[0].id,
    location: locations[0].id,
    shipping: shippingOptions[0].id,
    status: statuses[0].id,
  } as any);
  const [isValueDisabled, setValueDisabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const onFieldChange = (key: string, value: any) => {
    setValues({ ...values, [key]: value });
  };

  const discard = () => navigateToFeed();
  const submit = async () => {
    setIsSaving(true);
    try {
      const product = {
        ...values,
        images: values.images.map((url: string) => ({ url })),
      };

      if (!Number(values?.price?.value) || isValueDisabled) {
        delete product.price;
      }

      if (!productId) {
        const requestOptions = createProduct({ product })({
          isSSR: false,
          host: 'NA',
        });
        const httpClient = new HttpClient({
          baseURL: '/_api/bazaar/',
          headers: { Authorization: instance },
        });
        await httpClient.request(requestOptions);
        navigateToFeed();
      } else {
        const {
          createdDate,
          updatedDate,
          memberId,
          ...productToUpdate
        } = product;
        const requestOptions = updateProduct({ product: productToUpdate, mask: ['condition', 'description', 'images', 'location', 'name', 'price'] })({
          isSSR: false,
          host: 'NA',
        });
        const httpClient = new HttpClient({
          baseURL: '/_api/bazaar/',
          headers: { Authorization: instance },
        });
        delete requestOptions.data.product.id;
        await httpClient.request(requestOptions);
      }
      navigateToFeed();
    } catch (e) {
      setIsSaving(false);
      console.error(e);
    }

    setTimeout(() => {
      setIsSaving(false);
    }, 1000);
  };

  useEffect(() => {
    const fetchData = async (id: string) => {
      const requestOptions = getProduct({ productId: id })({
        isSSR: false,
        host: 'NA',
      });
      const httpClient = new HttpClient({
        baseURL: '/_api/bazaar/',
        headers: { Authorization: instance },
      });
      const prod: any = await httpClient.request(requestOptions);
      setValues(prod.data.product);
      if (prod?.data?.product?.price?.value === '0') {
        setValueDisabled(true);
      }
    };

    if (productId) {
      fetchData(productId);
    }
  }, []);

  const onImagesChange = useCallback(
    (images: string[]) => {
      setValues({ ...values, images });
    },
    [values],
  );

  return (
    <div className={st(classes.root, {})} data-hook="ProductForm-wrapper">
      <div className={st(classes.row, classes.noMargin)}>
        <Text typography={TextTypography.largeTitle}>Add new listing</Text>
      </div>
      <div className={st(classes.row)}>
        <Text typography={TextTypography.listText}>
          Please fill all the required information below.
        </Text>
      </div>
      <div className={st(classes.row)}>
        <TextField
          required={true}
          value={values.name}
          onChange={(e) => onFieldChange('name', e.target.value)}
          className={st(classes.inputWidth)}
          label="Title"
          placeholder="Enter your listing name"
        />

        <div className={st(classes.column)}>
          <div className={st(classes.row, classes.inputWidth)}>
            <TextField
              required={true}
              value={values?.price?.value}
              className={st(classes.price)}
              disabled={isValueDisabled}
              type="number"
              label="Price"
              placeholder="Enter listing price"
              onChange={(e) =>
                onFieldChange('price', {
                  ...values?.price,
                  value: e.target.value,
                })
              }
            />
            <Dropdown
              initialSelectedId={values?.price?.currency || currency[0].id}
              disabled={isValueDisabled}
              className={st(classes.currency)}
              label="Currency"
              options={currency}
              placeholder="Select"
              onChange={(item) =>
                onFieldChange('price', { ...values?.price, currency: item.id })
              }
            />
          </div>
          <Checkbox
            checked={isValueDisabled}
            label="This item is free"
            onChange={(e) => setValueDisabled(e.checked)}
          />
        </div>
      </div>

      <div className={st(classes.row)}>
        <TextArea
          value={values?.description}
          onChange={(e) => onFieldChange('description', e.target.value)}
          className={st(classes.inputWidth)}
          ariaLabel=""
          label="Description"
          placeholder="Add your listing description with all the relevant information (size, color, defects, etc.)"
        />
      </div>

      <div className={st(classes.row)}>
        <Dropdown
          initialSelectedId={values.condition || conditions[0].id}
          className={st(classes.inputWidth)}
          label="Condition"
          options={conditions}
          placeholder="Select the state of your listing"
          onChange={(item) => onFieldChange('condition', item.id)}
        />
        <Dropdown
          initialSelectedId={values.location || locations[0].id}
          className={st(classes.inputWidth)}
          label="Location"
          options={locations}
          placeholder="Select your location"
          onChange={(item) => onFieldChange('location', item.id)}
        />
      </div>
      <div className={st(classes.row)}>
        <Dropdown
          initialSelectedId={values.shipment || shippingOptions[0].id}
          className={st(classes.inputWidth)}
          label="Shipping Option"
          options={shippingOptions}
          placeholder="Select your preferred way of shipment"
          onChange={(item) => onFieldChange('shipment', item.id)}
        />
        <Dropdown
          initialSelectedId={values.status || statuses[0].id}
          className={st(classes.inputWidth)}
          label="Status"
          options={statuses}
          placeholder="Chooose the status"
          onChange={(item) => onFieldChange('status', item.id)}
        />
      </div>
      <Upload
        onChange={onImagesChange}
        images={values.images.map((image: any) =>
          typeof image === 'string' ? image : image.url,
        )}
      />
      <div className={st(classes.row, classes.flexEnd)}>
        <Button
          onClick={discard}
          className={st(classes.discardButton)}
          upgrade
          priority={ButtonPriority.secondary}
        >
          Discard
        </Button>
        <Button
          disabled={isSaving}
          onClick={submit}
          upgrade
          priority={ButtonPriority.primary}
        >
          {productId ? 'Update Listing' : 'Create Listing'}
        </Button>
      </div>
    </div>
  );
};

export default Widget;
