import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Add } from 'wix-ui-icons-common';
import Delete from 'wix-ui-icons-common/on-stage/Delete';
import Replace from 'wix-ui-icons-common/on-stage/Replace';
import { Image, Text, TextTypography } from 'wix-ui-tpa';
import { st, classes } from './Upload.st.css';
import EmptyUpload from './EmptyUpload';

interface UploadProps {
  onChange: (images: string[]) => void;
  images: string[];
}

const Upload: FC<UploadProps> = ({ onChange, images }) => {
  const ref = useRef(null);
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          if (base64String) {
            const newImages = [...images, base64String as string];
            if (ref.current) {
              const index = images.findIndex((i) => i === ref.current);
              newImages.splice(index, 1);
              ref.current = null;
            }
            onChange(newImages);
          }
        };
        reader.readAsDataURL(file);
      });
    },
    [images, onChange],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    maxSize: 200000,
    accept: ['.jpg', '.png', '.jpeg'],
  });

  return (
    <div data-hook="upload">
      <div className={st(classes.uploadContainer)}>
        <div className={st(classes.uploadRowContainer)}>
          <div className={st(classes.descriptionContainer)}>
            <Text typography={TextTypography.smallTitle}>Listing Images</Text>
            <Text>Please upload up to 5 images</Text>
          </div>
          {images.length < 5 && (
            <div
              style={{ display: 'flex', cursor: 'pointer', color: '#7247FF' }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Add />
              <Text className={st(classes.uploadImage)}>Upload Image</Text>
            </div>
          )}
        </div>
        <div className={st(classes.imageUploadContainer)}>
          {images.map((url: string) => (
            <div className={st(classes.imageContainer)}>
              <Image className={st(classes.image)} src={url} />
              <div
                className={st(classes.iconDelete, classes.icon)}
                onClick={() => {
                  const index = images.findIndex((i) => i === url);
                  const newImages = [...images];
                  newImages.splice(index, 1);
                  onChange(newImages);
                }}
              >
                <Delete style={{ fill: 'white' }} />
              </div>
              <div
                className={st(classes.iconReplace, classes.icon)}
                {...getRootProps()}
                onClick={(e) => {
                  (ref as any).current = url;
                  getRootProps().onClick!(e);
                }}
              >
                <input {...getInputProps()} />
                <Replace style={{ fill: 'white' }} />
              </div>
            </div>
          ))}
          {[...Array(5 - images.length).keys()].map(() => (
            <div className={st(classes.imageContainer)}>
              <EmptyUpload className={st(classes.image)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Upload;
