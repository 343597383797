import React, { FC } from 'react';

const EmptyUpload: FC<any> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 158 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="158" height="158" fill="#E8E6E6" />
      <path
        d="M108.5 111H49.5C48.837 111 48.2011 110.737 47.7322 110.268C47.2634 109.799 47 109.163 47 108.5V49.5C47 48.837 47.2634 48.2011 47.7322 47.7322C48.2011 47.2634 48.837 47 49.5 47H108.5C109.163 47 109.799 47.2634 110.268 47.7322C110.737 48.2011 111 48.837 111 49.5V108.5C111 109.163 110.737 109.799 110.268 110.268C109.799 110.737 109.163 111 108.5 111ZM49.5 48C49.1046 48.0077 48.7275 48.1682 48.4478 48.4478C48.1682 48.7275 48.0077 49.1046 48 49.5V108.5C48.0077 108.895 48.1682 109.273 48.4478 109.552C48.7275 109.832 49.1046 109.992 49.5 110H108.5C108.895 109.992 109.273 109.832 109.552 109.552C109.832 109.273 109.992 108.895 110 108.5V49.5C109.992 49.1046 109.832 48.7275 109.552 48.4478C109.273 48.1682 108.895 48.0077 108.5 48H49.5Z"
        fill="#646464"
      />
      <path
        d="M101.78 110.69L66.5201 75.2801C66.229 75.0066 65.8446 74.8543 65.4451 74.8543C65.0457 74.8543 64.6613 75.0066 64.3701 75.2801L47.8401 91.8801L47.1401 91.1801L63.6701 74.5901C64.1483 74.1335 64.784 73.8787 65.4451 73.8787C66.1063 73.8787 66.742 74.1335 67.2201 74.5901L102.48 110L101.78 110.69Z"
        fill="#646464"
      />
      <path
        d="M110.15 102.32L90.58 82.67C90.4408 82.5272 90.2744 82.4138 90.0906 82.3366C89.9068 82.2593 89.7094 82.2196 89.51 82.22C89.3091 82.2194 89.1101 82.2589 88.9246 82.3362C88.7392 82.4135 88.571 82.5269 88.43 82.67L81.72 89.4L81 88.71L87.73 82C88.2041 81.5318 88.8437 81.2695 89.51 81.27C89.8386 81.2689 90.1642 81.3328 90.468 81.4581C90.7718 81.5834 91.0477 81.7676 91.28 82L110.85 101.62L110.15 102.32Z"
        fill="#646464"
      />
      <path
        d="M93.75 70.78C92.458 70.7819 91.1945 70.4006 90.1194 69.6843C89.0442 68.968 88.2057 67.9489 87.7099 66.7558C87.2141 65.5628 87.0834 64.2495 87.3342 62.9822C87.5851 61.7148 88.2062 60.5503 89.119 59.6361C90.0319 58.7219 91.1954 58.099 92.4623 57.8462C93.7293 57.5934 95.0428 57.7221 96.2366 58.2161C97.4304 58.71 98.4508 59.547 99.1688 60.6211C99.8867 61.6951 100.27 62.958 100.27 64.25C100.267 65.9793 99.5799 67.6372 98.358 68.8609C97.1361 70.0847 95.4793 70.7747 93.75 70.78ZM93.75 58.71C92.6543 58.71 91.5832 59.0349 90.6721 59.6436C89.7611 60.2524 89.051 61.1176 88.6317 62.1299C88.2124 63.1422 88.1026 64.2561 88.3164 65.3308C88.5302 66.4054 89.0578 67.3926 89.8326 68.1673C90.6074 68.9421 91.5945 69.4698 92.6692 69.6835C93.7438 69.8973 94.8577 69.7876 95.87 69.3683C96.8823 68.9489 97.7476 68.2389 98.3563 67.3278C98.9651 66.4168 99.29 65.3457 99.29 64.25C99.2873 62.7815 98.7028 61.3739 97.6644 60.3355C96.626 59.2971 95.2185 58.7126 93.75 58.71Z"
        fill="#646464"
      />
    </svg>
  );
};

export default EmptyUpload;
